<template>
	<div id="app">
		<!-- <img src="./assets/logo.jpg"> -->
		<router-view ref="appView" />
	</div>
</template>

<script>
	export default {
		name: 'App',
		// metaInfo: {
		// 	title: 'HYTX-WMS'
		// },
		metaInfo() {
		  return {
		    // title: this.$t("meta.main")
				title: this.$store.state.sysShow.logoInfo.owner+'-WMS'
		  };
		},
		watch: {
			$route() {
				//切换标签时
				this.handleRefreshTable();
			}
		},
		created() {
			// let _this = this;
			//窗口改变时
			window.onresize = ()=> {
				this.handleRefreshTable();
			};
		},
		monted(){
			console.log('App.vue',monted);
			//查询logo
			// this.isGetLogoInfo();
			// this.$store.dispatch('UPDATE_SHOWCONF');
		},
		updated() {
			//数据改变时
			this.handleRefreshTable();
		},
		methods: {
			//表格重绘，房子出现fixed是错位
			handleRefreshTable() {
				this.$nextTick(() => {
					// 获取页面中已注册过ref的所有的子组件。
					let refList = this.$refs.appView.$refs;
					if (refList) {
						for (let i of Object.keys(refList)) {
							// 根据doLayout方法判断子组件是不是el-table
							if (refList[i] && refList[i].doLayout) {
								// 执行doLayout方法
								refList[i].doLayout();
							}
						}
					}

				})
			},
			
			//查询配置
			// isGetLogoInfo(){
				// let logoInfo = this.$store.getters.getLogoInfo;
				// console.log('isGetLogoInfo',logoInfo);
				// if(!logoInfo || !logoInfo.logo){
				// 	this.queryShowLogo();
				// }
				// this.$store.dispatch('UPDATE_SHOWCONF');
			// },

			//显示配置-logo
			// queryShowLogo() {
			// 	let domain = window.location.hostname;
			// 	this.$http
			// 		.get(this.$urlConfig.WhSysShowConfig + "?domain=" + domain)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			if ('200' === data.code && data.rows && data.rows[0]) {
			// 				this.$store.dispatch('UPDATE_SHOWCONF', data.rows[0]);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 		});
			// },

		}
	};
</script>

<style>
	#nprogress .bar {
		background: #206ce0 !important;
	}
</style>